
html {
  --infoIcon: url('https://bastel-bude.de/icons/info.svg');
  --sunIcon: url('https://bastel-bude.de/icons/sun.svg');
  --moonIcon: url('https://bastel-bude.de/icons/moon.svg');
  --nextIcon: url('https://bastel-bude.de/icons/next.svg'); }
@import "variables";
@import "base";
@import "nav";
@import "components";
@import "blog";
@import "utils";
@import "syntax";
@import "fonts";
@import "chart";
@import "custom";
